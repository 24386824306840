import { Component, OnInit, Inject } from '@angular/core'
import { COUNTRIES } from './../../../../company/stores/countries'
import { EUCOUNTRIES } from './../eu-countries'
import { AuthService } from '../../../../services/auth.service'
import { SelectSubscriptionModalComponent } from '../select-subscription-modal/select-subscription-modal.component'
import { GoLiveSalesModalComponent } from '../go-live-sales-modal/go-live-sales-modal.component'
import { GoLivePreAuthModalComponent } from '../go-live-pre-auth/go-live-pre-auth-modal.component'
import { PricingTablesComponent } from '../../../../auth/sign-up/pricing-tables/pricing-tables.component'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { checkVAT, countries, netherlands } from 'jsvat'


@Component({
  selector: 'business-information-modal',
  templateUrl: './business-information-modal.component.html',
  styleUrls: ['./business-information-modal.component.css']
})
export class BusinessInformationModalComponent implements OnInit {
  public countriesStore = COUNTRIES
  private euCountries = EUCOUNTRIES
  public isEu: boolean
  private country: string
  public showErrorMessage: boolean

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<BusinessInformationModalComponent>,
    public dialogRefSubs: MatDialogRef<SelectSubscriptionModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
  onNoClick(): void {
    this.dialogRef.close()
  }

  checkCountry(event) {
    this.isEu = false
    if (event.isUserInput) {
      setTimeout(() => {
        this.euCountries.map(eu => {
          if (event.source.viewValue === eu.name) {
            this.isEu = true
          }
        })
        this.country = event.source.value
      }, 200)
    }
  }

  saving(user) {
    this.showErrorMessage = false
    let info = user.billing_info
    if (info.organization && info.registration && info.address1 && info.city && this.country && info.zip) {
      this.dialogRef.close()
      user.billing_info.country = this.country
      this.authService.updateBillingInfo(user)
      this.openSubs()
    } else {
      this.showErrorMessage = true
    }
  }

  checkingVat(vat: string) {
    if (vat && vat.length) {
      let result = checkVAT(vat, countries)
      if(!result.isValid && result.country.name === "Brazil") {
        result = checkVAT(vat, [netherlands])
      }
      return result.isValid
    }
    return false
  }

  openSubs(): void {
    const dialogRef = this.dialog.open(PricingTablesComponent, {
      width: '60%',
      // data: { country: this.country }
    })

    dialogRef.afterClosed().subscribe(extension => {
        if(extension && extension._id) {
          const dialogRefGoLive = this.dialog.open(GoLivePreAuthModalComponent, {
            width: '60%',
            data: {extension: extension}
          })
        }
      })
  }
}
