import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core'
import { RequestService } from '../../services/request.service'
import { Employee } from '../../model/employee.model'
import { MatSelect } from '@angular/material/select'
import { MatOption } from '@angular/material/core'
@Component({
  selector: 'app-employee-selection',
  templateUrl: './employee-selection.component.html',
  styleUrls: ['./employee-selection.component.css']
})
export class EmployeeSelectionComponent implements OnInit {
  public employees: Employee[]
  private selectedEmployee: Employee
  @Output()
  sendSelectedEmployee = new EventEmitter<{ employee: Employee; index: number }>()
  @ViewChild('select') select: MatSelect
  constructor(private request: RequestService) {
    this.employees = []
  }

  ngOnInit() {
    this.getEmployees()
  }

  getEmployees() {
    this.request.getResource('employees').then((employees: Employee[]) => {
      this.employees = employees
      // this.selectedEmployee = employees[0]
    })
  }

  changeSelectedEmployee(event) {
    if (event?.isUserInput && event?.source?.value?._id) {
      this.selectedEmployee = event.source.value
      let index = this.employees.findIndex(s => s._id === this.selectedEmployee._id)
      this.sendSelectedEmployee.emit({ employee: this.selectedEmployee, index: index })
    } else {
      this.selectedEmployee = null
      this.sendSelectedEmployee.emit({ employee: null, index: null })
      this.select.options.forEach((item: MatOption) => item.deselect())
      this.select.close()
    }
  }
}
