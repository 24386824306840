import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable()
export class RequestService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = environment.api_server
  }

  getResource(resource: string, params?: any) {
    return this.http.get(`${this.url}${resource}`, { params }).toPromise()
  }

  postResource(resource: string, body?: Object) {
    return this.http.post(`${this.url}${resource}`, body).toPromise()
  }

  patchResource(resource: string, body?: Object) {
    return this.http.patch(`${this.url}${resource}`, body).toPromise()
  }

  putResource(resource: string, body?: Object) {
    return this.http.put(`${this.url}${resource}`, body).toPromise()
  }

  deleteResource(resource: string, params?: any) {
    return this.http.delete(`${this.url}${resource}`, { params }).toPromise()
  }

  searchResouce(resource: string, params?: any) {
    return this.http.get(`${this.url}${resource}/search`, { params }).toPromise()
  }
}
