import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core'
import { RequestService } from '../../services/request.service'
import { Device } from '../../model/device.model'
import { MatSelect } from '@angular/material/select'
import { MatOption } from '@angular/material/core'

@Component({
  selector: 'app-device-selection',
  templateUrl: './device-selection.component.html',
  styleUrls: ['./device-selection.component.css']
})
export class DeviceSelectionComponent implements OnInit {
  public devices: Device[]
  private selectedDevice: Device
  @Output()
  sendSelectedDevice = new EventEmitter<{ device: Device; index: number }>()
  @ViewChild('select') select: MatSelect

  constructor(private request: RequestService) {
    this.devices = []
  }

  ngOnInit() {
    this.getDevices()
  }

  getDevices() {
    this.request.getResource('devices').then((devices: Device[]) => {
      this.devices = devices ? devices.filter(d => d.device_type !== 'desktop') : []
      // this.selectedDevice = devices[0]
    })
  }

  changeSelectedDevice(event) {
    if (event?.isUserInput && event?.source?.value?._id) {
      this.selectedDevice = event.source.value
      let index = this.devices.findIndex(s => s._id === this.selectedDevice._id)
      this.sendSelectedDevice.emit({ device: this.selectedDevice, index: index })
    } else {
      this.selectedDevice = null
      this.sendSelectedDevice.emit({ device: null, index: null })
      this.select.options.forEach((item: MatOption) => item.deselect())
      this.select.close()
    }
  }
}
