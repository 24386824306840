import * as $ from 'jquery'
import { MediaMatcher } from '@angular/cdk/layout'
import { ChangeDetectorRef, Component, OnDestroy, OnInit, PipeTransform, Pipe } from '@angular/core'
import { MenuItems } from '../../shared/menu-items/menu-items'
import { AuthService } from './../../services/auth.service'
import { DataService } from '../../services/data.service'
import { User } from './../../model/user.model'
import { DomSanitizer } from '@angular/platform-browser'
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router'
import { environment } from '../../../environments/environment'

// /** @title Responsive sidenav */
// @Pipe({ name: 'safe' })
// export class SafePipe implements PipeTransform {
//   constructor(private sanitizer: DomSanitizer) {}
//   transform(url) {
//     return this.sanitizer.bypassSecurityTrustResourceUrl(url)
//   }
// }

@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList

  private _mobileQueryListener: () => void
  public showMenu: boolean
  private userSubscription: any
  private darkThemeSubscription: any
  public darkTheme: boolean
  public getUser: User
  public userPos: string
  public url: string
  private getNavigation: string
  public sidebarOpened: boolean
  public customerSupportOpen: boolean
  public customerSupportUrl: string
  public isAltuser: boolean

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private dataService: DataService,
    public router: Router
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    this.mobileQuery.addListener(this._mobileQueryListener)
    this.showMenu = false
    this.isAltuser = false
    this.sidebarOpened = false
    this.customerSupportOpen = false
    this.url = 'https://countrhq.com/'
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.getNavigation = event.url
      }
    })
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.getUserEvent().subscribe((user: User) => {
      if (user && user.username) {
        // const replaceDot = user.username.split('.').join('-')
        // this.userPos = replaceDot.split('@')[0]
        this.userPos = environment.production ? 'pos-convious' : 'test-convious'
      }
      this.getUser = user
      this.isAltuser = user.__t === 'Altuser' ? true : false
      this.showMenu = user ? true : false
      this.url = user ? 'index.html' : 'https://countrhq.com/'
    })
    this.darkThemeSubscription = this.dataService.getChangeTheme().subscribe((theme: string) => {
      this.darkTheme = theme === 'dark' ? true : false
    })

    this.darkTheme = this.dataService.getTheme() === 'dark' ? true : false
    this.getNavigation = this.router.url
    this.customerSupportUrl = `${
      environment.chat_url
    }?source=dashboard}&access_token=${this.authService.getToken()}&refresh_token=${this.authService.getRefreshToken()}`
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener)
  }

  showSearch() {
    ;(<any>$('#app-search')).toggle(200)
  }

  handleIframe() {
    return (this.customerSupportOpen = !this.customerSupportOpen)
  }
}
