import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Product } from '../model/product.model'

@Injectable()
export class ProductsService {
  private products: Product[]
  private count: number
  private allProductsCount: any
  private nutrientsArray: any[]
  constructor(private request: RequestService) {}

  fetchProducts(): void {
    this.request.getResource('products', { limit: 200 }).then((products: Product[]) => {
      this.products = products
      this.count = products.length
    })
    this.fetchNutrients()
  }

  /**
   * Get all products count without limits
   */
  findAllProductsCount(): void {
    this.request.getResource('products/count').then(count => {
      this.allProductsCount = count
    })
  }

  fetchNutrients(): void {
    this.request.getResource('nutrients').then((result: any[]) => {
      this.nutrientsArray = result
    })
  }

  getNutrients() {
    return this.nutrientsArray
  }

  getProducts(): Product[] {
    return Object.assign([], this.products)
  }

  getAllProductsCount(): number {
    return this.allProductsCount
  }

  getProductsCount(): number {
    return this.count
  }

  getProduct(id: string): Product {
    return { ...this.products.find(product => product._id === id) }
  }

  setProduct(product: Product): Promise<Product> {
    return this.request.patchResource('products/' + product._id, product).then((prod: Product) => {
      return Promise.resolve(prod)
    })
  }

  searchProduct(text: string) {
    const opts = {
      limit: 10,
      sort: '-updated_at',
      text: text
    }
    return this.request.searchResouce('products', opts).then((products: Product[]) => {
      return Promise.resolve(products)
    })
  }

  searchProductMasterList(text: string) {
    const opts = {
      limit: 10,
      sort: '-updated_at',
      text: text
    }
    return this.request.searchResouce('products/masterlist', opts).then((products: Product[]) => {
      return Promise.resolve(products)
    })
  }
}
