import { Component, OnChanges, Input } from '@angular/core'
import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'product-tile',
  templateUrl: 'product-tile.component.html',
  styleUrls: ['product-tile.component.scss']
})
export class ProductTileComponent implements OnChanges {
  public errorImg = 'assets/images/convious_icon.svg'
  @Input()
  productTile: any
  @Input()
  color: string

  constructor(private utils: UtilsService) {}

  ngOnChanges() {
    if (Object.keys(this.productTile).length > 0) {
      this.color = this.utils.colourNameToHex(this.color)
    }
  }
}
