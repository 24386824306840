import { Injectable, OnInit } from '@angular/core'

export interface BadgeItem {
  type: string
  value: string
}
export interface Saperator {
  name: string
  type?: string
}
export interface ChildrenItems {
  state: string
  name: string
  type?: string
}

export interface Menu {
  state: string
  name: string
  type: string
  icon: string
  badge?: BadgeItem[]
  saperator?: Saperator[]
  children?: ChildrenItems[]
}

const MENUITEMS = [
  {
    state: 'overview',
    name: 'overview',
    type: 'link',
    icon: 'av_timer'
  },
  {
    state: 'account',
    name: 'account',
    type: 'sub',
    icon: 'account_box',
    // badge: [{ type: 'red', value: '2' }],
    children: [
      { state: 'profile', name: 'profile' },
      { state: 'integrations', name: 'integrations' },
      { state: 'invoices', name: 'invoices' },
      { state: 'permissions', name: 'permissions' },
      { state: 'payment-methods', name: 'methods' }
    ]
  },
  {
    state: 'company',
    name: 'company',
    type: 'sub',
    icon: 'domain',
    // badge: [{ type: 'red', value: '3' }],
    children: [
      { state: 'stores', name: 'stores' },
      { state: 'devices', name: 'devices' },
      { state: 'receipt', name: 'receipt_layout' }
    ]
  },
  {
    state: 'assortment',
    name: 'assortment',
    type: 'sub',
    icon: 'shopping_cart',
    // badge: [{ type: 'red', value: '7' }],
    children: [
      { state: 'products', name: 'products' },
      { state: 'stock', name: 'stock' },
      { state: 'categories', name: 'categories' },
      { state: 'taxes', name: 'taxes' },
      { state: 'addons', name: 'addons' },
      { state: 'addon-groups', name: 'addon_groups' },
      { state: 'business-rules', name: 'business_rules' },
      { state: 'catalog-order', name: 'catalog_order' },
      { state: 'import-export', name: 'import_export' }
      // { state: 'suppliers', name: 'Suppliers' }
    ]
  },
  {
    state: 'sales-history',
    name: 'sales_history',
    type: 'sub',
    icon: 'insert_chart',
    // badge: [{ type: 'red', value: '2' }],
    children: [
      { state: 'sales-insights', name: 'sales_insights' },
      { state: 'product-insights', name: 'product_insights' },
      { state: 'store-insights', name: 'store_insights' },
      { state: 'transactions', name: 'transactions' },
      { state: 'refunds', name: 'refunds' },
      { state: 'register-operations', name: 'register_opertations' }
    ]
  },
  {
    state: 'changes-history',
    name: 'changes_history',
    type: 'sub',
    icon: 'history',
    children: [
      { state: 'products-history', name: 'product_history' },
      // { state: 'category-history', name: 'category_history' },
      // { state: 'device-history', name: 'device_history' },
      // { state: 'store-history', name: 'store_history' },
    ]
  },
  {
    state: 'customers',
    name: 'customers',
    type: 'link',
    icon: 'contact_mail'
  },
  {
    state: 'customer-groups',
    name: 'customer_groups',
    type: 'link',
    icon: 'groups'
  },
  {
    state: 'employees',
    name: 'employees',
    type: 'link',
    icon: 'people'
  },
  {
    state: 'table-layout',
    name: 'table_layout',
    type: 'link',
    icon: 'local_dining'
  },
  // {
  //   state: 'extensions',
  //   name: 'extensions',
  //   type: 'link',
  //   icon: 'merge_type'
  // },
  {
    state: 'desktop',
    name: 'desktop',
    type: 'link',
    icon: 'computer'
  },
  {
    state: 'vouchers',
    name: 'vouchers',
    type: 'link',
    icon: 'chrome_reader_mode'
  },
  {
    state: 'giftcards',
    name: 'giftcards',
    type: 'link',
    icon: 'card_giftcard'
  },
  {
    state: 'helpdesk',
    name: 'helpdesk',
    type: 'link',
    icon: 'info'
  },
  // {
  //   state: 'chat-iframe',
  //   name: 'chat_iframe',
  //   type: 'link',
  //   icon: 'chat'
  // },
  {
    state: 'old-dashboard',
    name: 'old_dashboard',
    type: 'link',
    icon: 'dvr'
  }
]

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS
  }
}
