import { Component, OnChanges, ViewChild, AfterViewInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { Report } from './../../../../model/report.model'
import { Input } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { CompareReportService } from '../../../../services/compare-reports.service'

import { MatSort } from '@angular/material/sort'

@Component({
  selector: 'data-table-sales',
  templateUrl: './data-table-sales.component.html',
  styleUrls: ['../data-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class DataTableSalesComponent implements OnChanges, AfterViewInit {
  @Input()
  report: Report
  @Input()
  isReportReady: boolean
  @Input()
  currency: string
  @Input()
  insightsType: string
  @Input()
  employeeReport: boolean
  @Input()
  customerReport: boolean
  @Input()
  compareReport: boolean
  @Input()
  selectedDay: any
  @ViewChild(MatSort) sort: MatSort
  @ViewChild('payments') payments: MatSort
  @ViewChild('channels') channels: MatSort
  @ViewChild('repsWaste') repsWaste: MatSort
  @ViewChild('discount') discount: MatSort
  @ViewChild('discountNumeric') discountNumeric: MatSort

  constructor(public compareService: CompareReportService) {}
  public displayedColumnsReports = ['name', 'number', 'amount']
  public displayedColumnsTaxes = ['name', 'amount', 'price']
  private data: any = []
  private dataTransactions: any = []
  private dataPayments: any = []
  private dataRefunds: any = []
  private dataDiscount: any = []
  private dataDiscountNumeric: any = []
  private dataTaxes: any = []
  private dataChannels: any = []
  private names: any = []
  public initialReportDate: string
  public finalReportDate: string
  public displayedColumns = ['revenue', 'amount']
  public selectedInsightsType: string
  public totalRepsAndWaste: any
  public totalDiscount: any
  public totalDiscountNumeric: any
  private utcOffset: number
  private isAverage: boolean
  private isDayReport: boolean
  public dataSourcePaymentToCompare: any = []
  public dataSourceChannelToCompare: any = []
  public dataSourceRespAndWasteToCompare: any = []
  public dataSourceRevenueTocompare: any = []
  public dataSourceTaxToCompare: any = []
  public dataSourceRefundToCompare: any = []
  public dataSourceTransactionToCompare: any = []
  public dataSourceDiscountToCompare: any = []
  public dataSourceDiscountToCompareNumeric: any = []

  dataSource: MatTableDataSource<any>
  dataSourceTransaction: MatTableDataSource<any>
  dataSourceRefund: MatTableDataSource<any>
  dataSourceTax: MatTableDataSource<any>
  dataSourceDiscount: MatTableDataSource<any>
  dataSourceDiscountNumeric: MatTableDataSource<any>

  dataSourcePayment: MatTableDataSource<any>
  dataSourceChannel: MatTableDataSource<any>
  dataSourceRespAndWaste: MatTableDataSource<any>
  expandedElement: any
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow')

  ngAfterViewInit() {
    setTimeout(() => this.ngAfterViewInitUtil(), 0)
  }

  ngAfterViewInitUtil() {
    this.dataSourcePayment.sort = this.payments
    this.dataSourceChannel.sort = this.channels
    this.dataSourceRespAndWaste.sort = this.repsWaste
    this.dataSourceDiscount.sort = this.discount
    this.dataSourceDiscountNumeric.sort = this.discountNumeric

    if (!this.compareReport) {
      this.compareService.setReportDates(this.report)
      this.compareService.setPaymentsReport(this.dataSourcePayment.filteredData)
      this.compareService.setChannelsReport(this.dataSourceChannel.filteredData)
      this.compareService.setRepsAndWasteReport(this.dataSourceRespAndWaste.filteredData)
      this.compareService.setRevenueReport(this.dataSource.filteredData)
      this.compareService.setTaxReport(this.dataSourceTax.filteredData)
      this.compareService.setRefundReport(this.dataSourceRefund.filteredData)
      this.compareService.setTransactionReport(this.dataSourceTransaction.filteredData)
      this.compareService.setTotalPayments(
        this.report.content[this.currency].tables.payment_by_method.summary
      )
      this.compareService.setTotalTransactions(
        this.report.content[this.currency].tables.transaction_by_method.summary
      )
      this.compareService.setTotalRefunds(
        this.report.content[this.currency].tables.refund_by_method.summary
      )
      this.compareService.setTotalTaxes(
        this.report.content[this.currency].tables.product_by_taxes.summary
      )
      this.compareService.setTotalRepsAndWaste(this.totalRepsAndWaste)
      this.compareService.setTotalDiscount(this.totalDiscount)
      this.compareService.setDisountReport(this.dataSourceDiscount.filteredData)
      this.compareService.setTotalDiscountNumeric(this.totalDiscountNumeric)
      this.compareService.setDiscountReportNumeric(this.dataSourceDiscountNumeric.filteredData)
    } else {
      const startDate = this.compareService.getReportStartDate()
      const endDate = this.compareService.getReportEndDate()
      if (startDate !== this.report.start_date && endDate !== this.report.end_date) {
        this.dataSourcePaymentToCompare = this.compareService.getPaymentreport()
        this.dataSourceChannelToCompare = this.compareService.getChannelReport()
        this.dataSourceRespAndWasteToCompare = this.compareService.getRepsAndWasteReport()
        this.dataSourceRevenueTocompare = this.compareService.getRevenueReport()
        this.dataSourceTaxToCompare = this.compareService.getTaxReport()
        this.dataSourceRefundToCompare = this.compareService.getRefundReport()
        this.dataSourceTransactionToCompare = this.compareService.getTransactionReport()
        this.dataSourceDiscountToCompare = this.compareService.getDisountReport()
        this.dataSourceDiscountToCompareNumeric = this.compareService.getDiscountReportNumeric()
      }
    }
  }

  ngOnChanges() {
    let reportType = localStorage.getItem('countr_report_type')
    if (reportType) {
      this.selectedInsightsType = reportType
    }

    this.createRevenue()
    this.createTransaction()
    this.createPayments()
    this.createRefunds()
    this.createDiscountPercentage()
    this.createDiscountNumeric()
    this.createTaxes()
    this.createChannels()
    this.createRespAndWaste()

    this.initialReportDate = new Date(this.report.start_date).toLocaleDateString()
    this.finalReportDate = new Date(this.report.end_date).toLocaleDateString()
  }

  showRowDetails(row: any, event?) {
    if (row === this.expandedElement) {
      this.expandedElement = null
    } else {
      this.expandedElement = row
    }
  }

  createRespAndWaste() {
    let data = []
    let total = {
      name: 'total',
      amount: 0,
      number: 0
    }

    if (this.report.content) {
      Object.keys(this.report.content[this.currency].tables.reps_waste_insights.details).map(
        key => {
          const keyValue = this.report.content[this.currency].tables.reps_waste_insights.details[
            key
          ]
          total.amount = total.amount + keyValue.amount
          total.number = total.number + keyValue.number
          data.push({
            name: key,
            amount: keyValue.amount,
            number: keyValue.number,
            items: keyValue.items
          })
        }
      )
    }
    this.totalRepsAndWaste = total
    this.dataSourceRespAndWaste = new MatTableDataSource(data)
  }

  createRevenue() {
    if (this.report.content) {
      this.data = []
      const grossRevenue = {
        name: 'gross_revenue',
        amount: this.report.content[this.currency].tables.revenue_summary.details.overall.amount
      }

      const deposits = {
        name: 'deposit',
        amount: this.report.content[this.currency].tables.revenue_summary.details.overall.deposit
      }

      const taxes = {
        name: 'taxes',
        amount: this.report.content[this.currency].tables.product_by_taxes.summary.taxes
      }

      const net_revenue = {
        name: 'net_revenue',
        amount:
          this.report.content[this.currency].tables.revenue_summary.details.overall.amount -
          this.report.content[this.currency].tables.product_by_taxes.summary.taxes
      }

      const cost = {
        name: 'cost',
        amount: this.report.content[this.currency].tables.revenue_summary.details.overall.cost
      }

      const totalAmount = grossRevenue.amount - cost.amount - taxes.amount

      const profit = {
        name: 'profit',
        amount: totalAmount
      }
      this.data.push(deposits)
      this.data.push(grossRevenue)
      this.data.push(taxes)
      this.data.push(net_revenue)
      this.data.push(cost)
      this.data.push(profit)
      this.dataSource = new MatTableDataSource(this.data)
    }
  }

  createTransaction() {
    if (this.report.content) {
      const method = this.report.content[this.currency].tables.transaction_by_method.details
      method.names = Object.keys(method)
      const transactions = []
      Object.keys(method).forEach(methodName => {
        if (methodName !== 'names') {
          transactions.push({
            name: methodName =='total cash after tips and change' ? 'total_cash_after_tips_and_change' : methodName,
            amount: method[methodName].amount,
            number: method[methodName].number
          })
        }
      })

      if ((method.card && method.card.details) || (method.online && method.online.details)) {
        transactions.forEach(transaction => {
          if (transaction.name === 'card' || transaction.name === 'online') {
            transaction.methods = []
            Object.keys(method[transaction.name].details).forEach(transactionName => {
              transaction.methods.push({
                name: transactionName,
                number: method[transaction.name].details[transactionName].number,
                amount: method[transaction.name].details[transactionName].amount
              })
            })
          }
          if (transaction.methods) {
            const transactionMethod = transaction.methods.map(method => {
              return method
            })
            const sumAmount = transactionMethod.reduce((c, v) => {
              return c + v.amount
            }, 0)
            const sumNumber = transactionMethod.reduce((c, v) => {
              return c + v.number
            }, 0)
            transaction.total = []
            transaction.total.push({
              totalAmount: sumAmount,
              totalNumber: sumNumber
            })
          }
        })
      }

      this.dataTransactions = transactions
      const rows = []
      this.dataTransactions.forEach(element => {
        if (element.methods) {
          rows.push(element, { detailRow: true, element })
        } else {
          rows.push(element)
        }
      })
      this.dataSourceTransaction = new MatTableDataSource(rows)
    }
  }

  createPayments() {
    if (this.report.content) {
      const payment = this.report.content[this.currency].tables.payment_by_method.details
      payment.names = Object.keys(payment)
      const transactions = []
      Object.keys(payment).forEach(paymentName => {
        if (paymentName !== 'names') {
          transactions.push({
            name: paymentName,
            amount: payment[paymentName].amount,
            number: payment[paymentName].number
          })
        }
      })

      if ((payment.card && payment.card.details) || (payment.online && payment.online.details)) {
        transactions.forEach(transaction => {
          if (transaction.name === 'card' || transaction.name === 'online') {
            transaction.methods = []
            Object.keys(payment[transaction.name].details).forEach(transactionName => {
              transaction.methods.push({
                name: transactionName,
                number: payment[transaction.name].details[transactionName].number,
                amount: payment[transaction.name].details[transactionName].amount
              })
            })
          }
          if (transaction.methods) {
            const transactionMethod = transaction.methods.map(method => {
              return method
            })
            const sumAmount = transactionMethod.reduce((c, v) => {
              return c + v.amount
            }, 0)
            const sumNumber = transactionMethod.reduce((c, v) => {
              return c + v.number
            }, 0)
            transaction.total = []
            transaction.amount = sumAmount
            transaction.number = sumNumber
            transaction.total.push({
              totalAmount: sumAmount,
              totalNumber: sumNumber
            })
          }
        })
      }

      this.dataPayments = transactions
      const rows = []
      this.dataPayments.forEach(element => {
        rows.push(element)
      })
      this.dataSourcePayment = new MatTableDataSource(rows)
    }
  }

  createChannels() {
    if (this.report.content) {
      const channel = this.report.content[this.currency].tables.product_by_channel.details
      channel.name = Object.keys(channel)
      const channels = []

      Object.keys(channel).forEach(channelName => {
        if (channelName !== 'name') {
          channels.push({
            name: channelName.replace('_', ' ').toUpperCase(),
            number: channel[channelName].summary.number,
            amount: channel[channelName].summary.amount,
            details: channel[channelName].details
          })
        }
      })
      channels.forEach(channel => {
        channel.stores = []
      })

      channels.forEach(channel => {
        Object.keys(channel.details).forEach(detail => {
          channel.stores.push({
            name: channel.details[detail].name.toUpperCase(),
            amount: channel.details[detail].summary.amount,
            number: channel.details[detail].summary.number,
            details: channel.details[detail].details
          })
        })
      })

      channels.forEach(channel => {
        channel.stores.forEach(store => {
          store.deliveries = []
        })
      })

      channels.forEach(channel => {
        channel.stores.forEach(store => {
          Object.keys(store.details).forEach(detail => {
            store.deliveries.push({
              name: store.details[detail].name,
              amount: store.details[detail].amount,
              number: store.details[detail].number
            })
          })

          for (let i = store.deliveries.length - 1; i >= 0; --i) {
            if (store.deliveries[i].name === 'restaurant') {
              store.deliveries[i].name = 'delivery'
            }
          }
        })
      })
      this.dataChannels = channels
      const rows = []
      this.dataChannels.forEach(element => {
        rows.push(element)
      })
      this.dataSourceChannel = new MatTableDataSource(rows)
    }
  }

  createDiscountPercentage() {
    if (this.report.content) {
      let total = {
        name: 'total',
        amount: this.report.content[this.currency].tables.product_by_discount_percentage.summary
          .amount
          ? this.report.content[this.currency].tables.product_by_discount_percentage.summary.amount
          : 0,
        number: this.report.content[this.currency].tables.product_by_discount_percentage.summary
          .number
          ? this.report.content[this.currency].tables.product_by_discount_percentage.summary.number
          : 0
      }

      const discount = this.report.content[this.currency].tables.product_by_discount_percentage
        .details
      discount.names = Object.keys(discount)
      const discounts = []
      Object.keys(discount).forEach(discountName => {
        if (discountName !== 'names') {
          discounts.push({
            name: `${(Number(discountName) * 100).toFixed(2)}%`,
            amount: discount[discountName].summary.amount,
            number: discount[discountName].summary.number
          })
        }
      })

      const sortedDisount = discounts.sort(function (a, b) {
        return Number(a.name.replace('%', '')) - Number(b.name.replace('%', ''))
      })

      this.dataDiscount = sortedDisount
      this.totalDiscount = total
      this.dataSourceDiscount = new MatTableDataSource(this.dataDiscount)
    }
  }

  createDiscountNumeric() {
    if (this.report.content) {
      let total = {
        name: 'total',
        amount: this.report.content[this.currency].tables.product_by_discount_numeric.summary.amount
          ? this.report.content[this.currency].tables.product_by_discount_numeric.summary.amount
          : 0,
        number: this.report.content[this.currency].tables.product_by_discount_numeric.summary.number
          ? this.report.content[this.currency].tables.product_by_discount_numeric.summary.number
          : 0
      }

      const discount = this.report.content[this.currency].tables.product_by_discount_numeric.details
      discount.names = Object.keys(discount)
      const discounts = []
      Object.keys(discount).forEach(discountName => {
        if (discountName !== 'names') {
          discounts.push({
            name: `${this.currency} ${Number(discountName).toFixed(2)}`,
            amount: discount[discountName].summary.amount,
            number: discount[discountName].summary.number
          })
        }
      })

      const sortedDisount = discounts.sort(function (a, b) {
        return (
          Number(a.name.replace(this ? this.currency : 'EUR', '')) -
          Number(b.name.replace(this ? this.currency : 'EUR', ''))
        )
      })

      this.dataDiscountNumeric = sortedDisount
      this.totalDiscountNumeric = total
      this.dataSourceDiscountNumeric = new MatTableDataSource(this.dataDiscountNumeric)
    }
  }

  createRefunds() {
    if (this.report.content) {
      const refund = this.report.content[this.currency].tables.refund_by_method.details
      refund.names = Object.keys(refund)
      const refunds = []
      Object.keys(refund).forEach(refundName => {
        if (refundName !== 'names') {
          refunds.push({
            name: refundName,
            amount: refund[refundName].amount,
            number: refund[refundName].number
          })
        }
      })

      this.dataRefunds = refunds
      this.dataSourceRefund = new MatTableDataSource(this.dataRefunds)
    }
  }
  createTaxes() {
    if (this.report.content) {
      const tax = this.report.content[this.currency].tables.product_by_taxes.details
      tax.names = Object.keys(tax)
      const taxes = []
      Object.keys(tax).forEach(taxName => {
        if (taxName !== 'names') {
          taxes.push({
            name: taxName,
            amount: tax[taxName].taxes,
            price: tax[taxName].amount
          })
        }
      })
      this.dataTaxes = taxes
      this.dataSourceTax = new MatTableDataSource(this.dataTaxes)
    }
  }

  totalTransactions() {
    return this.report.content[this.currency].tables.transaction_by_method.summary
  }

  totalPayments() {
    return this.report.content[this.currency].tables.payment_by_method.summary
  }

  totalRefunds() {
    if (
      this.report.content[this.currency].tables.refund_by_method.summary.number &&
      this.report.content[this.currency].tables.refund_by_method.summary.amount
    ) {
      return this.report.content[this.currency].tables.refund_by_method.summary
    } else return { number: 0, amount: 0 }
  }

  totalTaxes() {
    return this.report.content[this.currency].tables.product_by_taxes.summary
  }

  totalRepsWaste() {
    return this.totalRepsAndWaste
  }

  compareTotals(prevTot, newTot) {
    if (!prevTot) return '(new)'

    const newTotal = Math.abs(newTot)
    const prevTotal = Math.abs(prevTot)

    if (newTotal === 0 && prevTotal !== newTotal) {
      return `(-${(prevTotal * 100).toFixed(0)} %)`
    } else if (prevTotal > newTotal) {
      const increase = prevTotal - newTotal
      const result = (increase / newTotal) * 100
      return `(-${result.toFixed(0)} %)`
    } else if (prevTotal === newTotal) {
      return `(0 %)`
    } else {
      const decrease = newTotal - prevTotal
      const result = (decrease / newTotal) * 100

      return `(+${result.toFixed(0)} %)`
    }
  }
}
